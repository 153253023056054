(function($) {

   $(window).on('load', function() {
       $('.flexslider').flexslider({
           animation: "slide"
       });

   });

})(jQuery);

